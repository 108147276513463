module.exports.awsUpload = (parameters, file, fileData, callback) => {
    const mutableFile = file;
    const formData = new FormData();
    Object.keys(parameters.fields).forEach((name) => {
        formData.append(name, parameters.fields[name]);
    });
    if (!fileData) {
        mutableFile.error = 'Not found';
        return callback();
    }
    formData.append('file', fileData);
    mutableFile.bucketPath = parameters.fields.key;

    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener('progress', (evt) => {
        if (evt.lengthComputable) {
            const percentComplete = Math.round((evt.loaded * 100) / evt.total);
            mutableFile.percentCompleted = (`${percentComplete.toString()}%`);
        }
    }, false);
    xhr.addEventListener('load', (evt) => {
        if (evt.target.status >= 200 && evt.target.status < 300) {
            mutableFile.status = 'Uploaded';
            mutableFile.percentCompleted = '100%';
        } else {
            mutableFile.status = 'Error';
            mutableFile.error = 'Upload failed';
            mutableFile.percentCompleted = '0%';
            console.log('error', evt);
        }
        completedFile();
    }, false);
    xhr.addEventListener('error', (evt) => {
        mutableFile.status = 'Error';
        mutableFile.error = 'Upload failed';
        mutableFile.percentCompleted = '0%';
        completedFile();
    }, false);
    xhr.addEventListener('abort', (evt) => {
        mutableFile.status = 'Error';
        mutableFile.error = 'Upload cancelled';
        mutableFile.percentCompleted = '0%';
        completedFile();
    }, false);

    function completedFile() {
        if (callback) {
            callback();
            callback = null;
        }
    }

    xhr.open('POST', 'https://s3.amazonaws.com/lilo-app', true);
    xhr.send(formData);
};

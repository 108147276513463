<template>
<div
    class="kt-container kt-grid__item kt-grid__item--fluid"
    @dragleave="onDragLeave"
    @dragover="onDragOver"
    @drop="onDrop"
>
    <div
        v-if="showDropZone"
        class="w-100"
    >
        <a class="kt-header__brand-logo w-100 text-center">
            <SVGIcon
                name="dropZone"
                class="logo loading"
            />

            <h1 class="kt-font-bolder">
                Drop files here to upload!
            </h1>
        </a>
    </div>
    <template v-else>
        <b-overlay
            :variant="'transparent'"
            no-center
            opacity="1"
            blur="10px"
            :show="encryptionEnabled"
        >
            <template #overlay>
                <div class="text-center pt-4 kt-font-lg">
                    PII is currently encrypted. Please unlock PII to view this page.
                </div>
                <div class="text-center pt-4">
                    <button
                        v-if="encryptionEnabled"
                        type="button"
                        style="min-width: 160px;"
                        class="btn btn-label-brand btn-bold btn-upper mt-4"
                        @click="decrypt()"
                    >
                        Unlock PII
                    </button>
                </div>
            </template>
            <div class="row">
                <div class="col">
                    <div
                        class="alert alert-light alert-elevate fade show"
                        role="alert"
                    >
                        <div class="alert-icon">
                            <i class="flaticon-warning kt-font-brand" />
                        </div>
                        <div class="alert-text">
                            Select all Student Images in one folder, then drag and drop or browse and select them all to upload.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="kt-portlet kt-portlet--height-fluid">
                        <div class="kt-portlet__head">
                            <div class="kt-portlet__head-label">
                                <h3 class="kt-portlet__head-title">
                                    File Uploader
                                </h3>
                            </div>
                        </div>

                        <div class="kt-portlet__body">
                            <input
                                id="file-input"
                                type="file"
                                name="file"
                                style="display: none"
                                accept=".jpg"
                                multiple
                                @change="onFileBrowseSelect"
                            >

                            <div class="form-group pt-4">
                                <div
                                    class="input-group"
                                    style="cursor: pointer"
                                    @click.stop.prevent="triggerFileBrowser"
                                >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Choose Files"
                                        readonly="readonly"
                                    >
                                    <div class="input-group-append">
                                        <span class="input-group-text">
                                            Browse
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div
                    v-if="errorFiles.length !== 0"
                    class="col-xl-6 col-lg-6 col-md-6"
                >
                    <ErrorBin :error-files="errorFiles" />
                </div> -->
            </div>
            <div
                v-if="success"
                class="row"
            >
                <div class="col-12">
                    <div class="kt-demo__preview">
                        <div
                            class="alert alert-success"
                            role="alert"
                        >
                            <div class="alert-text">
                                <h4 class="alert-heading">
                                    Student Images Uploaded!
                                </h4>
                                <p class="mt-3">
                                    You have successfully imported new student images into SyncGrades. Reload this page to be sure you are seeing the most recent images.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <StudentImages />
                </div>
            </div>
        </b-overlay>
    </template>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import StudentImages from '../components/uploads/StudentImages.vue';
import * as imageUtilities from '../lib/imageUtilities';
import dataSystemMixins from '../store/mixins/dataSystemMixins';
import Types from '../store/Types';

export default {
    name: 'StudentImageUploader',
    components: {
        StudentImages,
    },
    mixins: [dataSystemMixins],
    data() {
        return {
            errorFiles: [],
            dragTimer: null,
            showDropZone: false,
            success: null,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        encryptionEnabled() {
            return this.$store.state.user.school.clientSideEncryptionEnabled
                && this.$store.state.user.school.serverSideEncryptionEnabled;
        },
        title() {
            const route = this.$route;
            return route.meta.title || null;
        },
        icon() {
            const route = this.$route;
            return route.meta.icon || null;
        },
        dataSystem() {
            return this.$_dataSystemMixins_getDataSystemForStudentImages;
        },
    },
    methods: {
        decrypt() {
            this.$store.commit(Types.mutations.SHOW_SCHOOL_PASSWORD_MODAL, true);
        },
        onFileBrowseSelect(e) {
            const files = Array.from(e.target.files);
            this.uploadFiles(files);
        },
        triggerFileBrowser() {
            document.getElementById('file-input').click();
        },
        uploadFiles(files) {
            const v = this;
            const { syncFile } = v.dataSystem.files[0];
            const { schoolTermEncryptionKey } = v.$store.state.database;
            imageUtilities.processFiles(v.user, files, syncFile, schoolTermEncryptionKey, (err) => {
                if (err) return v.showError(err);
                v.$store.dispatch(Types.actions.REFRESH_TABLE_BY_NAME, {
                    tableName: 'students',
                    callback: (err2) => {
                        if (err2) return v.showError(err);
                        window.console.log('studentsUpdate: Students have been repopulated');
                    },
                });
            });
        },
        onDragOver(e) {
            e.stopPropagation();
            e.preventDefault();

            const dt = e.dataTransfer;
            this.showDropZone = (dt.types && (dt.types.indexOf ? dt.types.indexOf('Files') != -1 : dt.types.contains('Files')));
        },
        onDragLeave(e) {
            e.stopPropagation();
            e.preventDefault();
            this.hideDropZone();
        },
        onDrop(e) {
            e.stopPropagation();
            e.preventDefault();
            const files = Array.from(e.dataTransfer.files);
            this.hideDropZone();
            this.uploadFiles(files);
        },
        hideDropZone() {
            const v = this;
            if (v.dragTimer) clearTimeout(v.dragTimer);
            v.dragTimer = setTimeout(() => {
                v.showDropZone = false;
            }, 25);
        },
        showSuccess() {
            this.success = true;
            setTimeout(() => {
                this.success = null;
            }, 30000);
        },
    },
};
</script>

<style scoped>
a.kt-header__brand-logo {
  height: 100vh;
  display: block;
  color: white;
}
.kt-header__brand-logo svg {
    opacity: 0.4;
    height: 50vh;
    max-width: 50vw;
}
.kt-header__brand-logo h1 {
    color: #0f88ef;
    font-size: 2.8rem;
    opacity: 0.4;
}
</style>

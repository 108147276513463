<template>
<div
    class="kt-portlet kt-portlet--fit kt-portlet--head-lg"
    :class="{
        'kt-portlet--skin-solid kt-portlet--head-overlay': backgroundColor !== '',
    }"
>
    <div
        class="kt-portlet__head kt-portlet__space-x"
        :class="{'kt-portlet__head--noborder': backgroundColor !== ''}"
    >
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                Student Images
                <small>
                    jpg
                </small>
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body kt-portlet__body--fit">
        <div
            class="kt-widget17"
        >
            <div
                v-if="backgroundColor !== ''"
                class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides"
                :style="backgroundColor"
            >
                <div class="kt-widget17__chart">
                    <div class="p-3" />
                </div>
            </div>
            <div
                class="kt-widget17__stats justify-content-center"
                :class="{'mt-3 pt-1': backgroundColor == ''}"
            >
                <div
                    class="kt-widget17__items ml-auto mr-auto"
                    style="width: 31%;"
                >
                    <span class="kt-widget17__item pl-3 pr-3 pt-4 pb-4">
                        <span
                            v-if="systemState == 'ERROR'"
                            class="kt-widget17__icon"
                        >
                            <SVGIcon
                                :hex-color="'#fd397a'"
                                name="file-error"
                            />
                        </span>
                        <span v-else-if="systemState.includes('Processing')">
                            <h4 class="kt-font-warning pt-3 text-capitalize">{{ systemState }}</h4>
                        </span>
                        <span v-else-if="systemState == 'UPLOADING'">
                            <h4 class="kt-font-success pt-3">Uploading</h4>
                        </span>
                        <span
                            v-else
                            class="kt-widget17__icon"
                        >
                            <SVGIcon name="jpg" />
                        </span>

                        <span class="kt-widget17__subtitle">
                            Student Images
                        </span>
                        <span class="kt-widget17__desc">
                            {{ statisticMessage }}
                        </span>
                        <span class="kt-widget17__desc mt-2">
                            {{ caption }}
                        </span>
                    </span>
                </div>
            </div>
            <div class="p-3" />
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import moment from 'moment';
import dataSystemMixins from '../../store/mixins/dataSystemMixins';
import studentMixins from '../../store/mixins/studentMixins';

export default {
    name: 'StudentImages',
    mixins: [studentMixins, dataSystemMixins],
    data() {
        return {
            states: {
                PARTIAL: 'PARTIAL', // Un-uploaded
                COMPLETE: 'COMPLETE', // Done
                UPLOADING: 'UPLOADING',
                UPLOADED: 'UPLOADED',
                ERROR: 'ERROR',
            },
        };
    },
    computed: mapState({
        user: (state) => state.user,
        dataSystem() {
            return this.$_dataSystemMixins_getDataSystemForStudentImages;
        },
        localFile() {
            if (!this.dataSystem) return null;
            const file = this.dataSystem.files[0] || null;
            if (!file) return null;
            return file.localFile || null;
        },
        uploadedFile() {
            if (!this.dataSystem) return null;
            const file = this.dataSystem.files[0] || null;
            if (!file) return null;
            return file.uploadedFile || null;
        },
        students() {
            return this.$_studentMixins_getStudents();
        },
        studentCount() {
            return this.students.length;
        },
        studentImageCount() {
            return this.students.filter((student) => student.pictureGuid != null).length;
        },
        systemState() {
            const {
                localFile, uploadedFile, states, studentImageCount,
            } = this;
            if (!uploadedFile && !localFile) return states.PARTIAL;
            if (localFile && localFile.filePresentation) {
                const { status } = localFile.filePresentation;
                if (status.includes('Processing')) return status;
                if (status === 'ERROR') return states.ERROR;
                if (status === 'UPLOADING') return states.UPLOADING;
            }
            if (uploadedFile.isUploaded && studentImageCount === 0) return states.ERROR;
            if (uploadedFile.isUploaded) return states.COMPLETE;
            return states.PARTIAL;
        },
        statisticMessage() {
            const {
                systemState, studentCount, studentImageCount, states,
            } = this;
            if (systemState !== states.COMPLETE) return '';
            if (studentCount === studentImageCount) return 'Uploaded for all students';
            return `Uploaded for ${studentImageCount} out of ${studentCount} students`;
        },
        caption() {
            const { systemState, uploadedFile, states } = this;
            if (systemState === states.PARTIAL) return 'Missing';
            if (systemState === states.COMPLETE) return `${moment.utc(uploadedFile.uploadDate).local(true).fromNow(true)} old`;
            if (systemState === states.ERROR) return 'File Error';
            return 'Pending';
        },
        backgroundColor() {
            const { systemState, states } = this;
            if (systemState == states.ERROR) {
                return 'background-color: #fd397a';
            }
            if (systemState == states.COMPLETE) {
                return 'background-color: rgb(0 123 255 / 80%)';
            }
            if (systemState == states.PARTIAL) {
                return 'background-color: #ffc107;';
            }
            return '';
        },
    }),
    mounted() {
    },
    methods: {
    },
};

</script>

<style scoped>
input[type="file"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
}
</style>

import store from '../store';
import Types from '../store/Types';

const moment = require('moment');
const async = require('async');
const { v4: uuidv4 } = require('uuid');
const image = require('./image');
const network = require('../network');
const fileUtilities = require('./fileUtilities');

let bucketDirectoryPath = null;

export { processFiles };

function processFile(user, file, databaseFiles, callback) {
    const mutableFile = file;
    async.auto({
        validate(next) {
            function onIdentified(err, f, fileType, identified) {
                const status = {
                    fileId: f.fileId,
                    percentCompleted: '0%',
                    status: err ? 'Error' : 'Waiting',
                    error: err,
                    show: true,
                    bucketPath: null,
                    fileName: f.name,
                    lastModified: moment(f.lastModified).format('ddd, MMM Do hA'),
                    dateModified: moment(f.lastModified).format('YYYY-MM-DD HH:mm:ss'),
                    fileType,
                    fileSize: `${(f.size / 1024 / 1024).toFixed(2)} MB`,
                };

                mutableFile.status = status;
                return identified();
            }

            if (mutableFile.name.toLowerCase().endsWith('.jpg')) {
                return image.identify(mutableFile, (err, fileType) => {
                    onIdentified(err, mutableFile, fileType, next);
                });
            }
            return onIdentified('Unknown, or unsupported, file type', mutableFile, null, next);
        },
        parameters: ['validate', function (results, next) {
            const { status } = mutableFile;
            const randomString = uuidv4();
            if (status.error) return next();
            status.status = 'Preparing';
            const params = {
                url: {
                    schoolId: user.school.schoolId,
                    schoolTermId: user.school.schoolTermId,
                    fileType: 'student-images',
                },
                body: {
                    fileParameters: {
                        fileName: `${randomString}.${status.fileType.extension}`,
                    },
                },
            };
            network.storage.getAWSFormDetails(params, (err, res) => {
                status.error = err;
                next(null, res);
            });
        }],
        aws: ['parameters', function (results, next) {
            const { status } = mutableFile;
            if (status.error) return next();

            fileUtilities.awsUpload(results.parameters, status, file, next);
        }],
        database: ['aws', function (results, next) {
            const { status } = mutableFile;
            if (status.error) return next();
            const extStudentId = status.fileName.split('.').shift();
            const pictureGuid = status.bucketPath.split('.').shift();
            if (!bucketDirectoryPath) bucketDirectoryPath = `${status.bucketPath.split('/').slice(0, 3).join('/')}/`;
            databaseFiles.push({
                extStudentId,
                pictureGuid,
            });
            return next();
        }],
    }, (err) => {
        const { status } = mutableFile;
        if (!status.error) {
            setTimeout(() => {
                status.show = false;
            }, status.fileType.name === 'studentImage' ? 500 : 2500);
        }
        return callback();
    });
}

function processFiles(user, fileObjects, syncFile, schoolTermEncryptionKey, callback) {
    const toProcess = [];
    fileObjects.forEach((fileObject) => {
        toProcess.push(fileObject);
    });
    const fileArray = Array.from(fileObjects);
    const databaseFiles = [];
    /*
        Being able to process images in parallel would be really great. We
        are processing about 4.6 images each second with a limit of 1. It
        would great if we could do better. Although, less than 70 seconds
        to process 300 images isn't that bad. It would take 36 minutes to
        do 10,000 images at that pace.

        Running eachOfLimit where limit > 1 causes issues, unfortunately,
        but I'd like to revisit trying to make this work. It will require
        a pretty heavy refactor of this code. For now we'll still with a
        limit of 1. - JCB
    */
    async.auto({
        processImages(next) {
            async.eachOfLimit(fileArray, 1, (file, idx, nextFile) => {
                updateVue({ status: `Processing ${idx} of ${toProcess.length}` }, fileObjects, syncFile);
                if (toProcess[idx].processed) return nextFile();
                toProcess[idx].processed = true;
                toProcess[idx].fileId = `file_${new Date().getTime()}`;
                processFile(user, file, databaseFiles, nextFile);
            }, (err) => next(err));
        },
        database: ['processImages', function (results, next) {
            updateVue({ status: 'UPLOADING' }, fileObjects, syncFile);
            if (databaseFiles.length === 0) return next();
            const params = {
                url: {
                    schoolId: user.school.schoolId,
                    schoolTermId: user.school.schoolTermId,
                },
                body: {
                    schoolTermEncryptionKey,
                    studentImages: databaseFiles,
                },
            };
            network.students.studentImage(params, (err, res) => {
                if (err) return next(err);
                return next();
            });
        }],
        syncFileUpload: ['database', function (results, next) {
            if (!bucketDirectoryPath) return next();
            const params = {
                url: {
                    schoolId: user.school.schoolId,
                    schoolTermId: user.school.schoolTermId,
                    internalName: 'studentImages',
                },
                body: {
                    bucketPath: bucketDirectoryPath,
                    fileSize: '0 KB',
                    fileLastModified: moment().format('llll'),
                },
            };
            network.storage.saveSyncFileDetails(params, (err, res) => {
                if (err) return next(err);
                next(null, res);
            });
        }],
    }, (err, res) => {
        if (err) {
            updateVue({ status: 'ERROR' }, fileObjects, syncFile);
            return store.dispatch(Types.actions.REFRESH_DATA_SYSTEMS, (error) => callback(err));
        }
        updateVue({ status: 'UPLOADED' }, fileObjects, syncFile);
        return store.dispatch(Types.actions.REFRESH_DATA_SYSTEMS, (error) => callback(err, res));
    });
}

function updateVue(filePresentation, localFile, syncFile) {
    if (!syncFile) return;
    store.commit(Types.mutations.EDIT_DATA_SYSTEM_SYNC_FILE, { filePresentation, localFile, syncFile });
}

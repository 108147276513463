var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "kt-container kt-grid__item kt-grid__item--fluid",
      on: {
        dragleave: _vm.onDragLeave,
        dragover: _vm.onDragOver,
        drop: _vm.onDrop,
      },
    },
    [
      _vm.showDropZone
        ? _c("div", { staticClass: "w-100" }, [
            _c(
              "a",
              { staticClass: "kt-header__brand-logo w-100 text-center" },
              [
                _c("SVGIcon", {
                  staticClass: "logo loading",
                  attrs: { name: "dropZone" },
                }),
                _c("h1", { staticClass: "kt-font-bolder" }, [
                  _vm._v(" Drop files here to upload! "),
                ]),
              ],
              1
            ),
          ])
        : [
            _c(
              "b-overlay",
              {
                attrs: {
                  variant: "transparent",
                  "no-center": "",
                  opacity: "1",
                  blur: "10px",
                  show: _vm.encryptionEnabled,
                },
                scopedSlots: _vm._u([
                  {
                    key: "overlay",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center pt-4 kt-font-lg" },
                          [
                            _vm._v(
                              " PII is currently encrypted. Please unlock PII to view this page. "
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "text-center pt-4" }, [
                          _vm.encryptionEnabled
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-label-brand btn-bold btn-upper mt-4",
                                  staticStyle: { "min-width": "160px" },
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.decrypt()
                                    },
                                  },
                                },
                                [_vm._v(" Unlock PII ")]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "alert alert-light alert-elevate fade show",
                        attrs: { role: "alert" },
                      },
                      [
                        _c("div", { staticClass: "alert-icon" }, [
                          _c("i", {
                            staticClass: "flaticon-warning kt-font-brand",
                          }),
                        ]),
                        _c("div", { staticClass: "alert-text" }, [
                          _vm._v(
                            " Select all Student Images in one folder, then drag and drop or browse and select them all to upload. "
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      { staticClass: "kt-portlet kt-portlet--height-fluid" },
                      [
                        _c("div", { staticClass: "kt-portlet__head" }, [
                          _c("div", { staticClass: "kt-portlet__head-label" }, [
                            _c(
                              "h3",
                              { staticClass: "kt-portlet__head-title" },
                              [_vm._v(" File Uploader ")]
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "kt-portlet__body" }, [
                          _c("input", {
                            staticStyle: { display: "none" },
                            attrs: {
                              id: "file-input",
                              type: "file",
                              name: "file",
                              accept: ".jpg",
                              multiple: "",
                            },
                            on: { change: _vm.onFileBrowseSelect },
                          }),
                          _c("div", { staticClass: "form-group pt-4" }, [
                            _c(
                              "div",
                              {
                                staticClass: "input-group",
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.triggerFileBrowser.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("input", {
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    placeholder: "Choose Files",
                                    readonly: "readonly",
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "input-group-append" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "input-group-text" },
                                      [_vm._v(" Browse ")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _vm.success
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("div", { staticClass: "kt-demo__preview" }, [
                          _c(
                            "div",
                            {
                              staticClass: "alert alert-success",
                              attrs: { role: "alert" },
                            },
                            [
                              _c("div", { staticClass: "alert-text" }, [
                                _c("h4", { staticClass: "alert-heading" }, [
                                  _vm._v(" Student Images Uploaded! "),
                                ]),
                                _c("p", { staticClass: "mt-3" }, [
                                  _vm._v(
                                    " You have successfully imported new student images into SyncGrades. Reload this page to be sure you are seeing the most recent images. "
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [_c("StudentImages")],
                    1
                  ),
                ]),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
const dataSystemMixins = {
    computed: {
        $_dataSystemMixins_getDataSystemForStarsAdmin() {
            const { dataSystems } = this.$store.state.database;
            return getDataSystemByName(dataSystems, 'STARS Admin');
        },
        $_dataSystemMixins_getDataSystemForStarsClassroom() {
            const { dataSystems } = this.$store.state.database;
            return getDataSystemByName(dataSystems, 'STARS Classroom');
        },
        $_dataSystemMixins_getDataSystemForAts() {
            const { dataSystems } = this.$store.state.database;
            return getDataSystemByName(dataSystems, 'ATS');
        },
        $_dataSystemMixins_getDataSystemForGoogle() {
            const { dataSystems } = this.$store.state.database;
            return getDataSystemByName(dataSystems, 'Google Linking');
        },
        $_dataSystemMixins_getDataSystemForStudentImages() {
            const { dataSystems } = this.$store.state.database;
            return getDataSystemByName(dataSystems, 'Student Images');
        },
        $_dataSystemMixins_getMarkingPeriodsWithUpload() {
            const { dataSystems, markingPeriods } = this.$store.state.database;
            const dataSystem = getDataSystemByName(dataSystems, 'STARS Admin');
            if (!dataSystem) return null;

            const files = dataSystem.files.filter((f) => {
                const { syncFile, uploadedFile } = f;
                if (syncFile.internalName.indexOf('highSchoolReportCardExcel_mp') !== 0) return false;
                if (!uploadedFile) return false;
                if (!uploadedFile.isImported) return false;
                if (!uploadedFile.isUploaded) return false;
                return true;
            }).map((f) => {
                const { bucketPath } = f.uploadedFile;
                const markingPeriod = f.syncFile.internalName.split('highSchoolReportCardExcel_mp')[1];
                return {
                    markingPeriod,
                    bucketPath,
                };
            });

            return markingPeriods.map((mp) => {
                const markingPeriod = { ...mp };
                markingPeriod.bucketPath = files.find((f) => f.markingPeriod == mp.markingPeriod) || null;
                return markingPeriod;
            });
        },
    },
};

function getDataSystemByName(dataSystems, dataSystem) {
    const data = dataSystems.find((d) => d.dataSystem == dataSystem) || null;
    if (!data) return null;
    return { ...data };
}

export { dataSystemMixins as default, getDataSystemByName };

module.exports.identify = function (file, callback) {
    const typeMatch = allowedFileTypes.find((fileType) => {
        const fileNameParts = file.name.split('.');
        const fileExtension = fileNameParts[fileNameParts.length - 1];
        return fileType.extension === fileExtension;
    });

    const nameMatch = file.name.match(/^[0-9]{9}\.jpg$/);
    if (!nameMatch) return callback('Invalid file name');

    if (!typeMatch) return callback('Unknown file type');
    return callback(null, typeMatch);
};

module.exports.cropImage = (context, image, dx, dy) => {
    const dMin = Math.min(image.width, image.height);
    const sx = (image.width - dMin) / 2;
    const sy = (image.height - dMin) / 10;
    context.drawImage(image, sx, sy, dMin, dMin, 0, 0, dx, dy);
};

const allowedFileTypes = [{
    name: 'studentImage',
    extension: 'jpg',
}];

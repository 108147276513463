var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "kt-portlet kt-portlet--fit kt-portlet--head-lg",
      class: {
        "kt-portlet--skin-solid kt-portlet--head-overlay":
          _vm.backgroundColor !== "",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "kt-portlet__head kt-portlet__space-x",
          class: { "kt-portlet__head--noborder": _vm.backgroundColor !== "" },
        },
        [_vm._m(0)]
      ),
      _c("div", { staticClass: "kt-portlet__body kt-portlet__body--fit" }, [
        _c("div", { staticClass: "kt-widget17" }, [
          _vm.backgroundColor !== ""
            ? _c(
                "div",
                {
                  staticClass:
                    "kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides",
                  style: _vm.backgroundColor,
                },
                [_vm._m(1)]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "kt-widget17__stats justify-content-center",
              class: { "mt-3 pt-1": _vm.backgroundColor == "" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "kt-widget17__items ml-auto mr-auto",
                  staticStyle: { width: "31%" },
                },
                [
                  _c(
                    "span",
                    { staticClass: "kt-widget17__item pl-3 pr-3 pt-4 pb-4" },
                    [
                      _vm.systemState == "ERROR"
                        ? _c(
                            "span",
                            { staticClass: "kt-widget17__icon" },
                            [
                              _c("SVGIcon", {
                                attrs: {
                                  "hex-color": "#fd397a",
                                  name: "file-error",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.systemState.includes("Processing")
                        ? _c("span", [
                            _c(
                              "h4",
                              {
                                staticClass:
                                  "kt-font-warning pt-3 text-capitalize",
                              },
                              [_vm._v(_vm._s(_vm.systemState))]
                            ),
                          ])
                        : _vm.systemState == "UPLOADING"
                        ? _c("span", [
                            _c("h4", { staticClass: "kt-font-success pt-3" }, [
                              _vm._v("Uploading"),
                            ]),
                          ])
                        : _c(
                            "span",
                            { staticClass: "kt-widget17__icon" },
                            [_c("SVGIcon", { attrs: { name: "jpg" } })],
                            1
                          ),
                      _c("span", { staticClass: "kt-widget17__subtitle" }, [
                        _vm._v(" Student Images "),
                      ]),
                      _c("span", { staticClass: "kt-widget17__desc" }, [
                        _vm._v(" " + _vm._s(_vm.statisticMessage) + " "),
                      ]),
                      _c("span", { staticClass: "kt-widget17__desc mt-2" }, [
                        _vm._v(" " + _vm._s(_vm.caption) + " "),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "p-3" }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head-label" }, [
      _c("h3", { staticClass: "kt-portlet__head-title" }, [
        _vm._v(" Student Images "),
        _c("small", [_vm._v(" jpg ")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-widget17__chart" }, [
      _c("div", { staticClass: "p-3" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }